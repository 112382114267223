import { MouseEvent, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/nextjs";
import { signIn } from "next-auth/react";
import Image from "next/image";

import logo from "assets/images/metworx-logo.svg";

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  left: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
}));

const Content = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const ImgContainer = styled("div")(() => ({
  marginBottom: "100px",
}));

const Message = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  marginBottom: theme.spacing(10),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

export const FingerprintForm: React.FC = () => {
  const [fingerprint, setFingerprint] = useState("");
  const [inputError, setInputError] = useState(false);

  const fingerprintLookup = async (lookup: string): Promise<string> => {
    try {
      const resp = await fetch(`/api/auth/fingerprint?lookup=${lookup}`);

      if (!resp.ok) {
        throw new Error("fingerprint lookup request failed");
      }

      const body = await resp.json();

      const fingerprint = body?.fingerprint;
      Sentry.setTag("fingerprint", fingerprint);
      return fingerprint;
    } catch (error) {
      Sentry.captureException(error);
      alert("Unable to lookup fingerprint");
    }

    return "";
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFingerprint(e.target.value);
  };

  const submit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!fingerprint) {
      setInputError(true);
      return;
    }

    const fp = await fingerprintLookup(fingerprint);
    if (fp) {
      await signInUsingFingerprint(fp);
    }
  };

  const onNoFingerprint = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const fp = await fingerprintLookup("");
    if (fp) {
      await signInUsingFingerprint(fp);
    }
  };

  const signInUsingFingerprint = async (fingerprint: string) => {
    const resp = await signIn(fingerprint, { callbackUrl: "/" });
    if (!resp?.ok && resp?.error) {
      Sentry.captureException(resp?.error);
    }
  };

  return (
    <Container>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          "& .MuiButtonBase-root": {
            marginTop: 10,
            marginLeft: 5,
          },
        }}
      >
        <Content>
          <ImgContainer>
            <Image src={logo} alt="logo" />
          </ImgContainer>
          <Message>
            {`Enter your organization's fingerprint. If you don't have a fingerprint, select "No Fingerprint".`}
          </Message>
          <TextField
            id="fingerprint"
            name="fingerprint"
            type="fingerprint"
            placeholder="Fingerprint"
            variant="outlined"
            color="primary"
            sx={{ width: "400px" }}
            helperText={inputError && "Fingerprint is required"}
            error={inputError}
            onChange={onChange}
          />
          <div>
            <Button type="submit" size="large" variant="contained" onClick={onNoFingerprint} color="secondary">
              No Fingerprint
            </Button>
            <Button type="submit" size="large" variant="contained" onClick={submit} onChange={onChange} color="primary">
              Submit
            </Button>
          </div>
        </Content>
      </Box>
    </Container>
  );
};

export default FingerprintForm;
